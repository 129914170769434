<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/Masknewbg.png')"/>
			<div class="titlebox w1239">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite"></div>
			</div>
			<div class="descopenbox">
				<div class="conbox w1239">
					<div class="box1 fontsize16">平台服务：为有效促进专利许可信息对接、提开专利许可谈判效率、降低专利许可交易风险，平台可为各交易主体提供信息发布、报名登记、签订合同、资金监管、存证备家的全流程、规范化服务。</div>
					<div class="box2 fontsize16">
						<div class="lable">申请材料下载：</div>
						<div class="vauleobj" v-for="(item,index) in fileList" :key="index">
							<a :href="item.fileUrl" target="_blank">{{index+1}}. {{item.name}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 最新专利 -->
		<!-- 知识产权展示 -->
		<div class="block58"></div>
		<div class="huaxue_openLicense_box">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">最新专利</div>
				<div class="type_desc fontsize14"></div>
			</div>
			<div class="huaxue_home_type w1239">
				<div class="title fontsize38"></div>
				<div class="typelist">
					<div class="typeobj fontsize22" @click="$util.routerPath('/openLicenseItem')">查看更多</div>
				</div>
			</div>
			<div class="huaxue_openLicense relative w1260">
				<div class="itemobj" v-for="(item,index) in openLicenseList" :key="index" @click="$util.routerPath('/openLicenseDetail',{uid:item.id})">
					<div class="img"><img :src="item.imgUrl || require('../assets/static/noimg.png')"></div>
					<!-- <div class="block10"></div> -->
					<div class="title fontsize16 clamp2">{{item.title || "暂无标题"}}</div>
					<div class="desc fontsize14">专利号：{{item.patentNo}}</div>
					<div class="desc fontsize14">许可期限：{{item.periodDate}}</div>
					<div class="taglist fontsize13">
						<div class="tag">{{item.openTypeName}}</div>
						<div class="tag">{{item.openTechnologyName}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 交易公示 -->
		<div class="block68" v-if="noticeList.length>0"></div>
		<div class="huaxue_new_type_box w1239">
			<div class="type_name fontsize38">相关政策</div>
			<div class="type_desc fontsize14"></div>
		</div>
		<div class="huaxue_home_type w1239">
			<div class="title fontsize38"></div>
			<div class="typelist">
				<div class="typeobj fontsize22" @click="$util.routerPath('/infoItem',{type:'xiangguanzhengce',typeName:'相关政策'})">查看更多</div>
			</div>
		</div>
		<!-- <div class="huaxue_home_type huaxue_deal_type w1239" v-if="noticeList.length>0">
			<div class="title fontsize38">相关政策</div>
			<div class="desc fontsize22" @click="$util.routerPath('/',{type:''})">查看更多</div>
		</div> -->
		<div class="deal_notice_box w1239" v-if="noticeList.length>0">
			<div class="notice_item pointer" v-for="(item,index) in noticeList" :key="index" @click="$util.routerPath('/infoDetail',{uid:item.id})">
				<div class="img"><img src="../assets/static/norice_icon.png"></div>
				<div class="title fl clamp fontsize18">{{item.title || "暂无标题"}}</div>
				<div class="timer fr fontsize18">{{item.timer}}</div>
			</div>
		</div>
		
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			fileList:[],
			openLicenseList:[],//最新专利
			noticeList:[],//类型栏目
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(22).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		//(文章)获取文件材料
		this.getOpenPatentFile()
		this.getOpenPatentInfo()
		//(文章)获取
		this.getXiangguanzhengce()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid }})
		},
		//(文章)获取文件材料
		getOpenPatentFile() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
				useContents:false
			};
			this.$http.post('frontEnd/open-patent/getOpenPatentFile', params).then(async function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					// records.forEach((item,index)=>{
					// 	if(item.releaseTime){
					// 		item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
					// 	}else{
					// 		item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
					// 	}
					// })
					_this.fileList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取最新专利
		getOpenPatentInfo() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 8,
				useContents:false
			};
			_this.$http.post('frontEnd/open-patent/getOpenPatentInfo', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						records.forEach((item,index)=>{
							// if(item.createDate){
							// 	item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
							// }
							// if(item.releaseTime){
							// 	item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
							// }
							if(item.imagesList&&item.imagesList!='[]'){
								try{
									item["imgUrl"] = JSON.parse(item.imagesList)[0]
								}catch(e){
									//TODO handle the exception
								}
							}
						})
						_this.openLicenseList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})	 
		},
		//(文章)获取
		getXiangguanzhengce() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 10,
				useContents:false
			};
			this.$http.post('frontEnd/open-patent/getXiangguanzhengce', params).then(async function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						if(item.releaseTime){
							item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
						}else{
							item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						}
					})
					_this.noticeList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
